import React from "react"
import { Link, graphql } from "gatsby"
import { Dialog } from "@blueprintjs/core"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import CloseIcon from "../components/CloseIcon"
import Layout from "../components/layout"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import { Button } from "../components/Button"
import { Image, Text } from "../components/Core"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {}
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState(prevState => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component
    }))
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]

    return (
      <SharedStateProvider>
        <Layout pageTitle="an-options">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />

          <Dialog
            canOutsideClickClose={true}
            enforceFocus={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            isOpen={this.state.dialogIsOpen}
            usePortal={true}
            onClose={this.toggleDialog}
            className="anesthesia-dialog">
            {this.state.componentShownInDialog}
          </Dialog>
          {/* <Heading heading={post.heading} blurb={post.topBlurbs} /> */}

          <div className="procedure procedure-video-top-wrapper">
            {post.imageId ? (
              <Image
                useAR
                publicId={post.imageId}
                className="procedure-hero-img"
              />
            ) : (
              <div className="nav-spacer"></div>
            )}

            <div className="columns procedure-heading-container">
              <div className="column is-2"></div>

              <div className="column">
                <div className="column procedures-theater-button is-hidden-tablet">
                  <div>
                    {post.youtube && (
                      <TheaterVideo
                        language="en"
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        controls={true}
                        playing={true}
                        overVideo={true}
                        hasWatchVideo={true}
                        buttonClass="contained mx-auto"
                      />
                    )}
                  </div>
                </div>

                <div className="procedure-video-top-headings single-procedure-heading">
                  <Text as="h1" text={post.heading} />
                </div>
              </div>

              <div className="column is-1"></div>

              <div className="column is-4 procedures-theater-button is-hidden-mobile">
                <div>
                  {post.youtube && (
                    <TheaterVideo
                      language="en"
                      videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                      controls={true}
                      playing={true}
                      overVideo={true}
                      hasWatchVideo={true}
                      buttonClass="contained"
                    />
                  )}
                </div>
              </div>
              <div className="column is-2"></div>
            </div>
          </div>

          <Section className="anesthesia-body-section">
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text text={post.body} />
              </div>
            </Columns>
          </Section>
          <Section>
            <Columns className="has-text-centered">
              <div className="column">
                <AnesthesiaModal clearBlurbLink />
              </div>
            </Columns>
          </Section>

          <Section>
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text text={post.bodyTwo} />
              </div>
            </Columns>
          </Section>

          <CallToAction
            colorBack
            heading={post.getStarted.heading}
            blurb={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        anesthesiaTypes {
          buttons {
            buttonText
            heading
            blurb
            youtube
            href
          }
          blurb
          noInlinkBlurb
          heading
        }
        youtube
        imageId
        body
        bodyTwo
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
        heading
        title
      }
    }
  }
`

export default AnesthesiaOptionsPage
